<template>
  <b-modal
    id="add-new-users-sidebar"
    ref="add-new-users-sidebar"
    :title="$t('Users.newUserTitle')"
    :ok-title="t('Planes.buttons.add')"
    :cancel-title="t('Planes.buttons.cancel')"
    :visible="isAddNewUsersSidebarActive"
    backdrop
    no-header
    hide-footer
    @change="(val) => $emit('update:is-add-new-users-sidebar-active', val)"
  >
    <!--    <template #default="{ hide }">-->
    <template>

      <!-- BODY -->
      <validation-observer
        #default="{ invalid }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          id="formAddUsers"
          class="p-2"
          @submit.prevent="addUser(emit, userData)"
          @reset.prevent="resetForm"
        >
          <!-- first_name -->
          <validation-provider
            #default="validationContext"
            :name="$t('Users.userList.firstName')"
            rules="required"
          >
            <b-form-group
              :label="$t('Users.userList.firstName')"
              label-for="firstName"
            >
              <b-form-input
                id="firstName"
                :maxlength="50"
                v-model="userData.first_name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('Users.userList.firstNamePlaceholder')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- last_name -->
          <validation-provider
            #default="validationContext"
            :name="$t('Users.userList.lastName')"
            rules="required"
          >
            <b-form-group
              :label="$t('Users.userList.lastName')"
              label-for="lastName"
            >
              <b-form-input
                id="lastName"
                :maxlength="50"
                v-model="userData.last_name"
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('Users.userList.lastNamePlaceholder')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- username -->
          <validation-provider
            #default="validationContext"
            :name="$t('Users.userList.username')"
            rules="required"
          >
            <b-form-group
              :label="$t('Users.userList.username')"
              label-for="username"
            >
              <b-form-input
                id="username"
                :maxlength="30"
                autocomplete="off"
                v-model="userData.username"
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('Users.userList.usernamePlaceholder')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- password -->
          <validation-provider
            #default="validationContext"
            :name="$t('Users.userList.password')"
            rules="required"
          >
            <b-form-group
              :label="$t('Users.userList.password')"
              label-for="password"
            >
              <b-form-input
                id="password"
                :maxlength="15"
                autocomplete="off"
                v-model="userData.password"
                type="password"
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('Users.userList.passwordPlaceholder')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- password confirm -->
          <validation-provider
            #default="validationContext"
            :name="$t('Users.userList.passwordConfirm')"
            rules="required"
          >
            <b-form-group
              :label="$t('Users.userList.passwordConfirm')"
              label-for="passwordConfirm"
            >
              <b-form-input
                id="passwordConfirm"
                :maxlength="15"
                autocomplete="off"
                v-model="userData.passwordConfirm"
                type="password"
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('Users.userList.passwordConfirmPlaceholder')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <div
                v-if="!checkPassword"
                class="alert-danger pl-1"
              >
                {{ $t('Users.error.password') }}
              </div>
            </b-form-group>
          </validation-provider>
          <!-- role_id -->
          <validation-provider
            #default="validationContext"
            :name="$t('Users.userList.roleName')"
            rules="required"
          >
            <b-form-group
              :label="$t('Users.userList.roleName')"
              label-for="roleName"
            >
              <v-select
                v-model="userData.role_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="userRolOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="roleName"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- DNI -->
          <validation-provider
            #default="validationContext"
            :name="$t('Users.userList.dni')"
            rules="required|integer|between:999999,999999999"
          >
            <b-form-group
              :label="$t('Users.userList.dni')"
              label-for="dni"
            >
              <b-form-input
                id="dni"
                :maxlength="9"
                v-model="userData.dni"
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('Users.userList.dniPlaceholder')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Email -->
          <validation-provider
            #default="validationContext"
            :name="$t('Users.userList.email')"
            rules= "email"
          >
            <b-form-group
              :label="$t('Users.userList.email')"
              label-for="email"
            >
              <b-form-input
                id="email"
                :maxlength="50"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('Users.userList.emailPlaceholder')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Phone -->
          <validation-provider
            #default="validationContext"
            :name="$t('Users.userList.phone')"
            rules="integer|between:9999999,999999999999"
          >
            <b-form-group
              :label="$t('Users.userList.phone')"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                :maxlength="12"
                v-model="userData.phone"
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('Users.userList.phonePlaceholder')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- submit button -->
          <div class="d-flex flex-wrap float-right mb-2 mt-1">
            <b-button
              variant="secondary"
              class="ml-5"
              data-dismiss="add-new-users-sidebar"
              :disabled="false"
              @click="hideModal(resetForm)"
            >
              {{ t('Planes.buttons.cancel') }}
            </b-button>
            <b-button
              variant="primary"
              class="ml-1"
              type="submit"
              :disabled="!checkPassword ? true :invalid"
            >
              {{ t('Planes.buttons.add') }}
              <feather-icon
                v-if="loader"
                icon="SettingsIcon"
                size="15"
                class="spinner"
              />
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormRadioGroup, BRow, BCol,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { onUnmounted, ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import usersStoreModule from '@/views/Users/usersStoreModule'
import useUserList from '@/views/Users/users-list/useUserList'
import userRolStoreModule from '@/views/Users-roles/userRolStoreModule'
import useUsersRolesList from '@/views/Users-roles/users-roles-list/useUsersRolesList'
import { loader } from '@core/mixins/ui/globalMixin'

export default {
  components: {
    BCol,
    BRow,
    flatPickr,
    BForm,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [loader],
  model: {
    prop: 'isAddNewUsersSidebarActive',
    event: 'update:is-add-new-users-sidebar-active',
  },
  props: {
    isAddNewUsersSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  computed: {
    checkPassword() {
      return (this.userData.password === this.userData.passwordConfirm)
    },
  },
  updated() {
    this.resetForm()
  },
  created() {
    this.getUsersRoles()
  },
  methods: {
    hideModal(resetForm) {
      resetForm()
      this.$refs['add-new-users-sidebar'].hide()
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      first_name: '',
      last_name: '',
      username: '',
      password: '',
      passwordConfirm: '',
      dni: '',
      email: '',
      role_id: '',
      phone: '',
    }

    const USERS_APP_STORE_MODULE_NAME = 'app-users'
    const USERS_ROLES_APP_STORE_MODULE_NAME = 'app-users-roles'

    // Register module
    if (!store.hasModule(USERS_APP_STORE_MODULE_NAME)) store.registerModule(USERS_APP_STORE_MODULE_NAME, usersStoreModule)
    if (!store.hasModule(USERS_ROLES_APP_STORE_MODULE_NAME)) store.registerModule(USERS_ROLES_APP_STORE_MODULE_NAME, userRolStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USERS_APP_STORE_MODULE_NAME)) store.unregisterModule(USERS_APP_STORE_MODULE_NAME)
      if (store.hasModule(USERS_ROLES_APP_STORE_MODULE_NAME)) store.unregisterModule(USERS_ROLES_APP_STORE_MODULE_NAME)
    })

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetUserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const toast = useToast()
    const {
      t,
      addUser,
    } = useUserList()

    const {
      getUsersRoles,
      userRolOptions,
    } = useUsersRolesList()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetUserData)

    return {
      addUser,
      userRolOptions,
      getUsersRoles,
      userData,
      toast,
      refFormObserver,
      getValidationState,
      resetForm,
      emit,
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
