import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { computed, ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import { dateLatin, title } from '@core/utils/filter'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import moment from 'moment'
import router from '@/router'

export default function useUserList() {
  const toast = useToast()
  const { t } = useI18nUtils()

  // Table Handlers
  const tableColumns = [
    {
      key: 'last_name',
      label: t('Users.userList.fullName'),
      sortable: true,
    },
    {
      key: 'username',
      label: t('Users.userList.username'),
      // formatter: title,
      sortable: true,
    },
    {
      key: 'dni',
      label: t('Users.userList.dni'),
      // formatter: title,
      sortable: false,
    },
    {
      key: 'email',
      label: t('Users.userList.email'),
      // formatter: title,
      sortable: true,
    },
    {
      key: 'role',
      label: t('Users.userList.roleName'),
      formatter: title,
      sortable: true,
    },
    {
      key: 'created',
      label: t('Users.userList.created'),
      // formatter: dateLatin,
      sortable: true,
    },
    {
      key: 'updated',
      label: t('Users.userList.updated'),
      // formatter: dateLatin,
      sortable: true,
    },
    {
      key: 'active',
      label: t('Users.userList.status'),
      formatter: title,
      sortable: true,
    },
    {
      key: 'actions',
      label: t('Users.userList.actions'),
      sortable: false,
    },
  ]

  const refUserListTable = ref(null)
  const roleOptions = ref([0])
  const userData = ref(null)
  const selectedUser = ref(null)
  const listUsers = ref(null)
  const totalRootClient = ref(0)
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchDataUsers = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter], () => {
    refetchDataUsers()
  })

  const getUserData = () => store.dispatch('app-users/getUser', { id: router.currentRoute.params.id })
    .then(response => {
      userData.value = response.data
      userData.value.role_id = response.data.role.id
    })
    .catch(error => {
      if (error.response.status === 404) {
        userData.value = undefined
      }
      console.log(error)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error al traer los datos del usuario',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  const getAllUsers = (ctx, callback) => {
    if (searchQuery.value.length == 0 || searchQuery.value.length >= 3) {
      store
        .dispatch('app-users/getAllUsers', {
          query: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          // clientStatus: statusFilter.value,
        })
        .then(response => {
          const { users, total } = response.data
          callback(users)
          userData.value = users
          totalUsers.value = total
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error al listar los usuarios',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    } else {
      callback(refUserListTable.value.localItems)
    }
  }
  const addUser = (emit, user) => {
    store.commit('enableLoader')
    store.dispatch('app-users/addUser', user)
      .then(() => {
        emit('refetch-data-users')
        emit('update:is-add-new-users-sidebar-active', false)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Aviso',
            text: 'Usuario agregado con éxito',
            icon: 'InfoIcon',
            variant: 'success',
          },
        })
        store.commit('disableLoader')
      }).catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: error.response.data.error,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        store.commit('disableLoader')
      })
  }
  const deleteUser = (emit, user) => {
    store.commit('enableLoader')
    store.dispatch('app-users/deleteUser', user)
      .then(async () => {
        emit('refetch-data-users')
        emit('update:is-delete-users-sidebar-active', false)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Aviso',
            text: 'Usuario desactivado',
            icon: 'InfoIcon',
            variant: 'success',
          },
        })
        store.commit('disableLoader')
      }).catch(error => {
        if (error.response.data) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: error.response.data.error,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
        store.commit('disableLoader')
      })
  }

  const editUserData = data => {
    store.commit('enableLoader')
    store.dispatch('app-users/editUser', data)
      .then(response => {
        // userData.value = response.data
        // userData.value.role_id = response.data.role.id

        toast({
          component: ToastificationContent,
          props: {
            title: 'Aviso',
            text: 'Usuario editado con éxito',
            icon: 'InfoIcon',
            variant: 'success',
          },
        })
        store.commit('disableLoader')
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 404) {
          userData.value = undefined
        }
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.error ? error.response.data.error : 'Error al editar los datos del usuario',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        store.commit('disableLoader')
      })
  }

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }
  const resolveUserStatusVariant = status => {
    if (status === true) return 'success'
    if (status === false) return 'danger'
    return 'warning'
  }
  const resolveUserStatus = status => {
    if (status === true) return t('Users.status.active')
    if (status === false) return t('Users.status.inactive')
    return t('Planes.status.pending')
  }
  const statusOptions = [
    { label: 'Activo', value: true },
    { label: 'Inactivo', value: false },
  ]

  const getDate = () => moment().startOf('day')

  return {
    getAllUsers,
    selectedUser,
    userData,
    addUser,
    deleteUser,
    editUserData,
    getDate,
    getUserData,
    listUsers,
    totalRootClient,
    roleOptions,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    refetchDataUsers,
    resolveUserRoleVariant,
    resolveUserStatusVariant,
    resolveUserStatus,
    statusOptions,
    // Extra Filters
    t,
  }
}
