import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { computed, ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import { title } from '@core/utils/filter'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import moment from 'moment'

export default function useUsersRolesList() {
  const toast = useToast()
  const { t } = useI18nUtils()

  // Table Handlers
  const tableColumns = [
    {
      key: 'role',
      label: t('Users.userList.roleName'),
      formatter: title,
      sortable: true,
    },
  ]
  const exclusedUserRolId = 3
  const refUserRolListTable = ref(null)
  const userRolOptions = ref([0])
  const userRolData = ref(null)
  const perPage = ref(10)
  const totalUserRol = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserRolListTable.value ? refUserRolListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUserRol.value,
    }
  })

  const refetchDataUsersRoles = () => {
    refUserRolListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter], () => {
    refetchDataUsersRoles()
  })

  const fetchUserRol = (ctx, callback) => {
    if (searchQuery.value.length == 0 || searchQuery.value.length >= 3) {
      store
        .dispatch('app-users-roles/fetchUsersRoles', {
          query: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          // clientStatus: statusFilter.value,
        })
        .then(response => {
          const { usersRoles, total } = response.data
          callback(usersRoles)
          userRolData.value = usersRoles
          totalUserRol.value = total
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error al listar los roles de usuarios',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    } else {
      callback(refUserRolListTable.value.localItems)
    }
  }

  const getUsersRoles = () => store.dispatch('app-users-roles/fetchUsersRoles')
    .then(response => {
      const { userRoles } = response.data
      const op = []
      userRoles.map(rol => {
        if (rol.id != exclusedUserRolId) {
          op.push({
            label: rol.name,
            value: rol.id,
            description: rol.description,
          })
        }
      })
      userRolOptions.value = op
    })
    .catch(error => {
      console.log(error)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error al listar los roles de usuarios',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const getDate = () => moment().startOf('day')

  return {
    getUsersRoles,
    fetchUserRol,
    getDate,
    userRolOptions,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refetchDataUsersRoles,

    // Extra Filters
    t,
  }
}
