<template xmlns="http://www.w3.org/1999/html">
  <b-modal
    id="delete-users-sidebar"
    ref="delete-users-sidebar"
    :title="t('Users.deleteUserTitle')"
    :visible="isDeleteUsersSidebarActive"
    backdrop
    no-header
    hide-footer
    @change="(val) => $emit('update:is-delete-users-sidebar-active', val)"
  >
    <!--    <template #default="{ hide }">-->
    <template>
      <b-row class="w-100 my-4 ">
        <b-col cols="1" />
        <b-col
          cols="11"
          class=""
        >
          ¿ {{ t('Users.message.delete') }}
          <b-badge
            pill
            variant="light-primary"
          >

            {{ selectedUser.full_name }}
          </b-badge> ?
        </b-col>
        <b-col cols="1" />
        <b-col cols="11" />
      </b-row>

      <!-- submit button -->
      <div class="container-fluid  mx-0 my-0 px-0 py-0 mb-2">
        <b-row class="w-100">
          <b-col class="col-5 " />
          <b-col class="col-3 float-right">
            <b-button
              variant="secondary"
              data-dismiss="delete-user-sidebar"
              :disabled="false"
              @click="hideModal"
            >
              {{ t('actions.cancel') }}
            </b-button>
          </b-col>
          <b-col class="col-3 float-right ml-1">
            <b-button
              variant="danger"
              @click="deleteUser(emit, selectedUser)"
            >
              {{ t('actions.disableTitle') }}
              <feather-icon
                v-if="loader"
                icon="SettingsIcon"
                size="15"
                class="spinner"
              />
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BBadge,
  BButton, BCol, BRow,
} from 'bootstrap-vue'

import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import usersStoreModule from '@/views/Users/usersStoreModule'
import useUserList from '@/views/Users/users-list/useUserList'
import { loader } from '@core/mixins/ui/globalMixin'

export default {
  components: {
    BBadge,
    BCol,
    BRow,
    BButton,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isDeleteUsersSidebarActive',
    event: 'update:is-delete-users-sidebar-active',
  },
  props: {
    isDeleteUsersSidebarActive: {
      type: Boolean,
      required: true,
    },
    selectedUser: {
      type: Object,
      default: null,
      required: true,
    },
  },
  mixins: [loader],

  methods: {
    hideModal() {
      this.$refs['delete-users-sidebar'].hide()
    },
  },
  setup(props, { emit }) {
    const USERS_APP_STORE_MODULE_NAME = 'app-users'

    // Register module
    if (!store.hasModule(USERS_APP_STORE_MODULE_NAME)) store.registerModule(USERS_APP_STORE_MODULE_NAME, usersStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USERS_APP_STORE_MODULE_NAME)) store.unregisterModule(USERS_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const { t, deleteUser } = useUserList()
    return {
      emit,
      deleteUser,
      toast,
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-membership-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
