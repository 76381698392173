import useJwt from '../../auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAllUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .post('/user/search', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .get(`/user/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .post('/user', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .patch(`/user/${userData.id}`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .delete(`/user/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
