import useJwt from '../../auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsersRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .post('/user/role/search', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getUserRol(ctx, { id }) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .get(`/user/role/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
